import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, SilentRequest } from '@azure/msal-browser';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoginPage: boolean = false;

  constructor(
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private msalService: MsalService,

  ) { }

  ngOnInit(): void {
    // Handle route changes to determine if the current page is the login page
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isLoginPage = this.router.url === '/login';
    });

    // Handle MSAL login success event to get the SSO ID
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as any;
        const ssoId = payload.account.name; // Assuming localAccountId is the SSO ID
        if (ssoId) {
          this.authService.setSsoId(ssoId);
        }
        else {
          this.authService.setSsoId('');
        }        this.authService.setSsoId(ssoId);
        this.authService.storeCandidateInitials(payload.account.name);
      });
    this.checkAccount();
  }

  private checkAccount() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      console.log('account', account)

      const ssoId = account.name; // Assuming localAccountId is the SSO ID
      if (ssoId) {
        this.authService.setSsoId(ssoId);
      }
      else {
        this.authService.setSsoId('');
      }
      this.authService.storeCandidateInitials(account.name);
    }

  }
}