 
    <div>
      <p><strong>Chères exploratrices, chers explorateurs,</strong></p>
      <p>
        Embarquons ensemble pour une aventure unique, une exploration captivante
        qui marquera la croissance audacieuse de notre Groupe !&nbsp;
      </p>
      <p>
        Alors que notre empreinte s’étend à la conquête de nouveaux horizons,
        aussi bien en France qu’en Europe, nous sommes en quête de nos futurs
        coéquipiers d’aventure prêts à se joindre à notre expédition
        palpitante.&nbsp;
      </p>
      <p>
        En tant qu’explorateurs chevronnés, vous êtes - sans conteste - les mieux
        placés pour inciter et guider de nouveaux talents à nous rejoindre.&nbsp;
      </p>
      <p>Bonne exploration !&nbsp;</p>
      <p>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong
          >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;LES MODALITES D’EXPLORATION</strong
        >
      </h2>
      <p>&nbsp;</p>
      <p><strong>POUR QUI ?</strong>&nbsp;</p>
      <p>
        Tous les collaborateurs sans exception peuvent participer au programme de
        cooptation (sous les conditions détaillées ci-dessous) !
      </p>
      <p>
        Vous êtes stagiaire ou alternant ? Vous pouvez aussi coopter !&nbsp;<br />&nbsp;
      </p>
      <figure class="table">
        <table>
          <tbody>
            <tr>
              <td rowspan="2"><strong>Profil cooptant</strong></td>
              <td colspan="2"><strong>Profil coopté</strong></td>
            </tr>
            <tr>
              <td>Consultants</td>
              <td>BM / BP / TA</td>
            </tr>
            <tr>
              <td><strong>Consultants</strong></td>
              <td>Eligibles</td>
              <td>Eligibles</td>
            </tr>
            <tr>
              <td><strong>BM (IA, M, MC, RA)</strong></td>
              <td>Non éligibles</td>
              <td>Eligibles*</td>
            </tr>
            <tr>
              <td><strong>BP</strong></td>
              <td>Eligibles</td>
              <td>Eligibles *</td>
            </tr>
            <tr>
              <td><strong>TA</strong></td>
              <td>Non éligibles</td>
              <td>Non éligibles</td>
            </tr>
            <tr>
              <td><strong>DA / DD</strong></td>
              <td>Non éligibles</td>
              <td>Non éligibles</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        <i
          >*Conditions d’éligibilité aux primes : vous n’êtes pas éligibles à la
          prime de cooptation ou de recrutement si vous recrutez une personne que
          vous allez encadrer.</i
        >
      </p>
      <p>
        <br /><i><strong>QUELS PROFILS à coopter ?</strong></i>
      </p>
      <p>
        Tous profils recrutés en CDI n’existant pas dans notre outil de gestion
        des candidatures ou sur lesquels l’équipe Recrutement n’a pas fait
        d’actions durant les 6 derniers mois.&nbsp;
      </p>
      <figure class="table">
        <table>
          <tbody>
            <tr>
              <td><strong>Qui&nbsp;?</strong></td>
              <td><strong>Consultant</strong></td>
              <td><strong>Business Manager</strong></td>
              <td><strong>Talent Acquisition</strong></td>
              <td><strong>Business Partner</strong></td>
            </tr>
            <tr>
              <td><strong>Son rôle</strong></td>
              <td>
                <p>3 domaines d'expertise :</p>
                <p>- Recherche &amp; Développement</p>
                <p>- Production &amp; Opérations</p>
                <p>- Digital</p>
              </td>
              <td>
                <p>- Créer &amp; développer leur activité commerciale</p>
                <p>- Recruter leur équipe d’ingénieurs consultants</p>
                <p>- Manager leur équipe</p>
              </td>
              <td>
                <p>- Répondre aux enjeux de recrutement</p>
                <p>- Incarner la promesse employeur</p>
                <p>- Être garant de l’expérience</p>
                <p>Candidat</p>
              </td>
              <td>
                <p>- Administration des ventes</p>
                <p>- Administration du personnel</p>
                <p>- Finance</p>
                <p>- Comptabilité</p>
                <p>- RH</p>
                <p>- Communication</p>
                <p>- Qualité...</p>
              </td>
            </tr>
            <tr>
              <td><strong>Son profil</strong></td>
              <td>- Formation Bac+5</td>
              <td>
                - Ecole d’ingénieurs ou de commerce ayant jusqu'à 5 ans
                d'expérience
              </td>
              <td>- Formation Bac+5</td>
              <td>- Formation Bac+5</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        <strong>NB : </strong>Pour les recrutements sur les agences en France, les
        candidats doivent être habilités à travailler sur le territoire (ie. Les
        candidatures nécessitant la délivrance de visa pour arriver sur le
        territoire européen ne seront donc pas prises en compte).&nbsp;<br /><br /><i
          ><strong>QUELLES SONT LES PRIMES ALLOUEES ?</strong></i
        >
      </p>
      <p>
        Nous encourageons tous nos collaborateurs à participer activement à la
        croissance de notre Groupe.&nbsp;Vos recommandations peuvent jouer un rôle
        essentiel dans la constitution d'une équipe solide et
        performante.&nbsp;&nbsp;<br /><br /><strong
          >Ainsi, nous vous proposons deux types de prime en cas de recrutement
          d’un profil de vos réseaux :&nbsp;</strong
        >
      </p>
      <p>
        <strong>❶ La prime de cooptation</strong><br />√ Vous connaissez
        personnellement et/ou professionnellement la personne cooptée que vous
        recommandez&nbsp;: vous vous portez garant de ses compétentes et de sa
        bonne intégration au sein du Groupe.
      </p>
      <p>
        √&nbsp; Vous lui présentez notre Groupe, nos pôles d’expertise ainsi que
        nos métiers.&nbsp;
      </p>
      <p>√&nbsp; Vous transmettez sa candidature&nbsp;: nous la recrutons.</p>
      <p><strong>→ Son montant = 1000 € (2000 CHF)</strong><br />&nbsp;</p>
      <p>
        <strong>❷ La prime de recrutement</strong><br />√&nbsp; Vous avez contacté
        un consultant via votre réseau (LinkedIn, Alumni…), vous faîtes passer le
        CV d’une connaissance…&nbsp;: vous faites de la mise en relation
      </p>
      <p>
        √&nbsp; Vous lui présentez notre Groupe, nos pôles d’expertise ainsi que
        nos métiers.&nbsp;
      </p>
      <p>√&nbsp; Vous transmettez sa candidature&nbsp;: nous la recrutons.</p>
      <p><strong>→ Son montant = 500 € (1000 CHF)</strong></p>
      <p>
        NB : En cas de doublon, seule la première « cooptation » reçue via notre
        plateforme dédiée sera comptabilisée.&nbsp;Par ailleurs, les CV issus des
        CVthèques ne seront pas pris en compte pour cette prime car nos TA les
        sélectionnent déjà.&nbsp;
      </p>
      <p>
        <br /><i
          ><strong
            >QUELLES SONT LES MODALITES DE VERSEMENT DE CES PRIMES ?</strong
          ></i
        >
      </p>
      <p>
        Que ce soit pour les primes de cooptation ou de recrutement, celles-ci
        sont versées en deux fois&nbsp;:
      </p>
      <p>
        -<strong>Une première prime</strong>, représentant 50% de la prime totale,
        versée sur la paye du mois suivant l’intégration du coopté.
      </p>
      <p>
        -<strong>Une seconde prime</strong>, représentant 50% de la prime totale,
        versée sur la paye du mois suivant la confirmation de la période d’essai
        du coopté.&nbsp;<br /><br />NB : Pour les pays où il n’y a pas de période
        d’essai, la seconde partie de prime est versée après 4 mois d’activité du
        nouvel embauché.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>QUAND COOPTER ?</strong><br />Les cooptations doivent être
        transmises via la plateforme dédiée. C’est le point de départ du process
        de recrutement pour le coopté. Les candidatures cooptées ne peuvent être
        prises en considération à postériori du process de recrutement.<br />&nbsp;
      </p>
    </div>
