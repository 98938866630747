import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Agency } from '../data/agency';
import { Cooptation, CooptationResponse } from '../data/cooptation';

@Injectable({
  providedIn: 'root'
})
export class CooptationService {

  readonly apiURLorigin = 'https://testjobs.abylsen.com/backendLaravel/public/api/v1/cooptations';

  constructor(private http: HttpClient) {
  }

  getAgencies(): Observable<Agency> {
    return this.http.get<Agency>(this.apiURLorigin + '/agencies');
  }

  submitCooptation(cooptationData: FormData): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(this.apiURLorigin + '/cooptation/create', cooptationData);
  }


  submitReminder(reminderData: {
    email: string,
    name: string
  }): Observable<CooptationResponse> {
    return this.http.post<CooptationResponse>(this.apiURLorigin + '/cooptation/reminder', reminderData);
  }

  GetCooptationsByUserId(ssoId: string): Observable<Cooptation[]> {
    return this.http.get<Cooptation[]>(`${this.apiURLorigin}/cooptation/cooptant/${ssoId}`);
  }

  getVisitsNumber(ssoId: string): Observable<{ total_visits: number }> {
    return this.http.get<{ total_visits: number }>(`${this.apiURLorigin}/cooptation/visits/stats/${ssoId}`);
  }

}
