import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Job } from '../../data/job';
import { environment } from '../../../environments/environment.full';
import { JobSearchService } from '../../services/job-search.service';
import { CooptationFormComponent } from '../cooptation-form/cooptation-form.component';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  public assetsUrl = environment.assetsUrl;

  @Input()
  public job!: Job;

  @Input()
  public isApply = false;

  moreJobs?: Job[];
  jobsSubscription?: Subscription;

  @ViewChild('addThisDiv', { read: ElementRef, static: true }) addThisDiv!: ElementRef;
  @ViewChild('addThisDivResponsive', { read: ElementRef, static: true }) addThisDivResponsive!: ElementRef;

  private dlg?: MatDialogRef<any>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private service: JobSearchService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit(): void {
    if (this.addThisDiv && this.addThisDivResponsive) {
      this.addThisDiv.nativeElement.setAttribute('addthis:url', window.location.href);
      this.addThisDivResponsive.nativeElement.setAttribute('addthis:url', window.location.href);
      try {
        (window as any).addthis.layers.refresh();
      } catch (e) { }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isApply && !this.dlg) {
      this.isApply = false;
      this.apply();
    }
    if (this.job) {
      if (this.jobsSubscription) {
        this.moreJobs = undefined;
        this.jobsSubscription.unsubscribe();
      }
      this.jobsSubscription = this.service.jobs$.subscribe(jobs => {
        const j = jobs.find(j => j.uid === this.job.uid);
        const index = j ? jobs.indexOf(j) : -1;
        if (index < 0) {
          // first 3
          this.moreJobs = jobs.slice(0, Math.min(3, jobs.length));
        } else {
          // next jobs
          this.moreJobs = jobs.slice(index + 1, Math.min(index + 4, jobs.length));
          if (this.moreJobs.length < 3 && index > 0) {
            // add previous ones
            this.moreJobs = this.moreJobs.concat(jobs.slice(0, Math.min(index, 3 - this.moreJobs.length)));
          }
        }
        if (this.moreJobs.length === 0) {
          this.moreJobs = undefined;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.jobsSubscription) {
      this.jobsSubscription.unsubscribe();
    }
  }

  public viewAllOffers() {
    this.router.navigate(['/'], { queryParamsHandling: 'preserve', state: { 'fromJobId': this.job?.uid } });
  }

  public apply() {
    this.dlg = this.dialog.open(CooptationFormComponent, {
      width: '60%',
      height: '90%',
      data: { job: this.job }
    });
    this.dlg.afterClosed().subscribe(() => {
      this.dlg = undefined;
    });
  }

}
