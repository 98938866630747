<div class="container">
  <div class="logo" [ngSwitch]="branding.brand.name">
    <a *ngSwitchCase="'abylsen'" href="https://www.abylsen.com" target="_blank">
      <img src="{{ assetsUrl }}/logo-abylsen.png" />
    </a>
  </div>


  <div class="menu">
    <nav mat-tab-nav-bar color="primary" mat-align-tabs="center" #tabs [disablePagination]="true" [tabPanel]="tabPanel">
      <a mat-tab-link routerLink="/" routerLinkActive [active]="isOffersActive()" queryParamsHandling="preserve">
        {{ "Offers" | translate | uppercase }}
      </a>


      <a mat-tab-link routerLink="/my-cooptation" routerLinkActive [active]="myCoopt()">
        {{ "my cooptations" | translate | uppercase }}
      </a>

      <a mat-tab-link routerLink="/faq" routerLinkActive [active]="faq()">
        {{ "faq" | translate | uppercase }}
      </a>

    </nav>


    <div class="profile-icon" [ngSwitch]="branding.brand.name" [matMenuTriggerFor]="menu">
      <div *ngIf="isAuthenticated" class="logged-in">
        <div class="circle">{{ authService.getCandidateInitials() }}</div>
      </div>
      <mat-menu #menu="matMenu" class="custom-menu">
        <button *ngIf="isAuthenticated" class="menu-item" (click)="logout()" mat-menu-item>
          {{ "Log out" | translate }}
        </button>
      </mat-menu>
    </div>

  </div>
  <div class="languages">
    <img src="{{ assetsUrl }}/en.png" (click)="setLanguage('en')" />
    <img src="{{ assetsUrl }}/fr.png" (click)="setLanguage('fr')" />
  </div>
  <mat-tab-nav-panel #tabPanel> </mat-tab-nav-panel>
</div>