<div class="login-page">
  <div class="login-image">
    <img class="bg" src="../../../assets/login-bg.png" alt="" />
  </div>
  <div class="login-form">
    <div class="form-content">
      <h2>{{ "READY_TO_EXPLORE" | translate }}</h2>
      <div class="sso-options">
        <button class="colored-button" (click)="login()" [style.color]="'black'" [style.background-color]="'white'">{{
          "SSO_connexion" | translate }}</button>
      </div>
    </div>
</div>
</div>