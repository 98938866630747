import { Component } from '@angular/core';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrl: './faq-content.component.scss'
})
export class FaqContentComponent {

}
