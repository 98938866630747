import { Cooptation } from './../../data/cooptation';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobSearchService } from '../../services/job-search.service';
import { DialogService } from '../../services/dialog.service';
import { CooptationService } from '../../services/cooptation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-cooptation-card',
  templateUrl: './cooptation-card.component.html',
  styleUrl: './cooptation-card.component.scss'
})
export class CooptationCardComponent {
  @Input() currentState: number = 0; // State from 0 to 5
  @Input() rating: number = 0; // Rating from 1 to 3
  @Input() cooptation!: Cooptation;
  @Output() relanceEvent = new EventEmitter<Cooptation>();

  steps = [
    { label: 'En attente de validation', icon: 'hourglass_empty' },
    { label: 'Entretien en cours', icon: 'people' },
    { label: 'Proposition faite', icon: 'assignment_turned_in' },
    { label: 'Résultat de la candidature', icon: 'check_circle' },
    { label: 'Prime de 500$', icon: 'monetization_on' }
  ];

  constructor(

    public service: JobSearchService,
    public dialogService: DialogService,
  ) {

  }

  public relance(coopt: Cooptation) {

    this.relanceEvent.emit(coopt);
  }



  getRecLevel(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.recommandation_level
    } else {
      return 1
    }
  }


  getState(cooptation: Cooptation) {
    if (cooptation) {
      return cooptation.state
    } else {
      return 0
    }
  }



}
