import { Injectable } from '@angular/core';

class Brand {
  constructor(
    public name: string,
    public displayName: string,
    public query: string,
    public hostname: string,
    public isMultiBranding: boolean,
    public connexysName: string,
    public spontaneousApplicationLink: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  public brand!: Brand;

  private brands = [
    new Brand('abylsen', 'Abylsen', 'abylsen', '.abylsen.com', true, 'Abylsen', 'https://jobsearch.abylsen.com/candidature-spontanee/'),
    new Brand('mykelson-consulting', 'Mykelson-Consulting', 'mykelson-consulting', '.mykelson-consulting.com', false, 'Mykelson-Consulting', 'https://mykelson-consulting.com/'),
    new Brand('belgatech', 'Belgatech', 'belgatech', '.belgatech-engineering.com', false, 'Belgatech', 'https://belgatech-engineering.com/contacts/'),
    new Brand('pi', 'PI', 'pi', '.pilifesciences.com', false, 'PI', 'https://pilifesciences.com/work-at-pi/spontaneous-application/'),
    new Brand('pharmacos', 'Pharmacos', 'pharmacos', '.pharmacos.fr', false, 'Pharmacos', 'https://www.pharmacos.fr/en/jobs-2/'),
  ];

  constructor(
  ) {
    let brand: Brand | undefined;
    if (window.location.search) {
      for (const b of this.brands) {
        if (window.location.search.indexOf('brand=' + b.query) >= 0) {
          brand = b;
          break;
        }
      }
    }
    if (!brand && window.location.hostname) {
      for (const b of this.brands) {
        if (window.location.hostname.indexOf(b.hostname) >= 0) {
          brand = b;
          break;
        }
      }
    }
    if (!brand) {
      brand = this.brands[0];
    }
    this.setBrand(brand);
  }

  public setBrand(brand: Brand): void {
    this.brand = brand;
    window.document.documentElement.className = 'branding-' + brand.name;
  }

  public setMultiBrand(nameBrand: string, status: boolean): void {
    this.brand.isMultiBranding = status;
  }

}
