import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobPageComponent } from './pages/job-page/job-page.component';
import { JobSearchPageComponent } from './pages/job-search-page/job-search-page.component';
import { MyCooptationComponent } from './components/my-cooptation/my-cooptation.component';
import { FAQComponent } from './components/faq/faq.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', component: JobSearchPageComponent, canActivate: [MsalGuard] },
  { path: 'job/:jobId', component: JobPageComponent , canActivate: [MsalGuard] },
  { path: 'job/:jobId/apply', component: JobPageComponent , canActivate: [MsalGuard] },
  { path: 'my-cooptation', component: MyCooptationComponent , canActivate: [MsalGuard] },
  { path: 'faq', component: FAQComponent , canActivate: [MsalGuard] },
  { path: 'login', component: LoginPageComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
