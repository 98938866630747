import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private ssoIdSubject = new BehaviorSubject<string>('');
  ssoId$ = this.ssoIdSubject.asObservable();
  initialsKey = 'initials';

  setSsoId(ssoId: string) {
    this.ssoIdSubject.next(ssoId);
  }

  public getCandidateInitials() {
    return (
      localStorage.getItem(this.initialsKey)
    );
  }

  public storeCandidateInitials(initials: string | undefined) {
    if (initials)
      localStorage.setItem(this.initialsKey, this.getInitials(initials));
    else
    localStorage.setItem(this.initialsKey,'');
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }

}