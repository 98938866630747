import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
 import { JobFiltersComponent } from '../job-filters/job-filters.component';
import { JobSearchService } from '../../services/job-search.service';

@Component({
  selector: 'app-job-search-criteria',
  templateUrl: './job-search-criteria.component.html',
  styleUrls: ['./job-search-criteria.component.scss']
})
export class JobSearchCriteriaComponent {

  public title: string;
  private title$: BehaviorSubject<string>;

  constructor(
    private service: JobSearchService,
    private bottomSheet: MatBottomSheet
  ) {
    this.title = service.criteria.title;
    this.title$ = new BehaviorSubject(this.title);
    this.title$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(newTitle => {
      if (service.criteria.title != newTitle) {
        service.criteria.title = newTitle;
        service.resetJobs();
      }
    });
  }

  public titleChanged() {
    this.title$.next(this.title);
  }

  public openFilters() {
    this.bottomSheet.open(JobFiltersComponent);
  }

}
