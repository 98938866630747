import { Injectable } from '@angular/core';
import { TranslationsService } from './translations.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  private lang = '';
  readonly apiURLorigin = 'https://testjobs.abylsen.com/backendLaravel/public/api/v1/cooptations';


  constructor(
    private translationService: TranslationsService,
    private http: HttpClient,

  ) { }

  share(socialMedia: string, jobURL: string, jobTitle?: string) {

    this.lang = this.translationService.getLanguage().toLowerCase();

    switch(socialMedia){
      case 'mail': this.shareWithMail(jobURL, jobTitle); break;
      case 'twitter': this.shareOnTwitter(jobURL); break;
      case 'linkedin': this.shareOnLinkedIn(jobURL); break;
      case 'facebook': this.shareOnFacebook(jobURL); break;
    }
  }

    private shareOnTwitter(jobURL: string): void {
      let tweetText: string;

      if (this.lang === 'fr') {
          tweetText = `🚀 Nouvelle opportunité ! Découvrez notre offre et partagez avec vos contacts ! 🌟`;
      } else {
          tweetText = `🚀 New opportunity! Explore our exciting offer and share with your contacts! 🌟`;; // Default to English
      }

      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${jobURL}`;

      window.open(twitterShareUrl, '_blank');
  }

  private shareOnLinkedIn(jobURL: string): void {
      const postText = this.lang === 'fr' ? 'Découvrez cette opportunité d\'emploi : ' : 'Check out this job opportunity: ';
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${jobURL}&summary=${encodeURIComponent(postText)}`;

      window.open(linkedInShareUrl, '_blank');
  }

  private shareWithMail(jobURL: string, jobTitle?: string): void {
      let subject : string;
      let body : string;
      
      if (this.lang === 'fr') {
        subject = 'Offre d\'emploi : ' + jobTitle;
        body = `
    Bonjour,\n
    Nous avons une nouvelle opportunité à explorer ! 🚀\n
    Découvrez notre dernière offre passionnante en cliquant sur le lien ci-dessous :
    ${jobURL}\n
    N'hésitez pas à partager cette exploration avec vos contacts susceptibles d'être intéressés.
    Merci pour votre soutien !\n
    Cordialement,
        `;
    } else {
        // Default to English
        subject = 'Job Offer : ' + jobTitle;
        body = `
    Hello,\n\n
    We have a new opportunity to explore! 🚀\n
    Discover our latest exciting offer by clicking on the link below:
    ${jobURL}\n
    Feel free to share this exploration with your contacts who might be interested.
    Thank you for your support!\n
    Best regards,
        `;
    }    
      const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
  }


  private shareOnFacebook(jobURL: string): void {

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(jobURL)}`;
    window.open(facebookShareUrl, '_blank');
  }

  getShareLink(IdOffer?: string): Observable<string> {
    if (IdOffer)
      return this.http.get<string>(this.apiURLorigin + '/cooptation/generate-link/' + IdOffer);
    else
      return this.http.get<string>(this.apiURLorigin + '/cooptation/generate-link');
  }
}
