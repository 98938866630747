import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsService } from '../../services/translations.service';
import { CooptationService } from '../../services/cooptation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CooptationFormComponent } from '../cooptation-form/cooptation-form.component';
import { Cooptation } from '../../data/cooptation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedMethods } from '../../shared/shared-methods';

@Component({
  selector: 'app-cooptations-list',
  templateUrl: './cooptations-list.component.html',
  styleUrl: './cooptations-list.component.scss'
})
export class CooptationsListComponent {

  public rating: number = 2 // Rating from 1 to 3
  public currentState: number = 3; // State from 0 to 5
  private dlg?: MatDialogRef<any>;
  @Input() cooptationsList!: Cooptation[];
  @Output() refreshCooptantList = new EventEmitter<string>();



  constructor(
    public cooptationService: CooptationService,
    private dialog: MatDialog,
    private translate: TranslationsService,
    private sharedMethod: SharedMethods
  ) {
  }

  public coopt() {
    this.dlg = this.dialog.open(CooptationFormComponent, {
      width: '60%',
      height: '90%',
    });
    this.dlg.afterClosed().subscribe(() => {
      this.dlg = undefined;
      this.refreshCooptantList.emit('refresh')

    });
  }


  public relance(coopt: Cooptation) {

    if (coopt) {
      this.cooptationService.submitReminder({ email: coopt.email, name: coopt.firstname }).subscribe({
        next: () => {
          this.dialog.closeAll();
          this.sharedMethod.openSnackBar(this.translate.translate('reminder sent success'), "OK");
        }, error: (error) => {
          console.error('error sent reminder', error);
          this.sharedMethod.openSnackBar(this.translate.translate('error sent reminder'), "OK");
        }
      });
    }
    else
      this.sharedMethod.openSnackBar(this.translate.translate('error sent reminder'), "OK");
  }



}
