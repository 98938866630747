<div class="side-elements">
  <div class="copy-container">
    <div class="share-label">{{'share'| translate}}:</div>

    <mat-form-field   class="custom-form-field"  style="width: 100%;" appearance="outline">
    <input (click)="copyToClipboard()" matInput type="text" [(ngModel)]="inputText"/>
    <mat-icon (click)="copyToClipboard()" class="icon-align" matSuffix>link</mat-icon>
</mat-form-field>

    <div class="share-it">
      <div class="share-options">
        <div class="icon-wrapper" (click)="shareOffer('mail')">
          <mat-icon>mail</mat-icon>
        </div>
        <div class="icon-wrapper" (click)="shareOffer('linkedin')">
          <img src="./assets/icons/linkedin-black.svg" alt="LinkedIn" />
        </div>
        <div class="icon-wrapper" (click)="shareOffer('twitter')">
          <img src="./assets/icons/twitter-black.svg" alt="Twitter" />
        </div>
        <div class="icon-wrapper" (click)="shareOffer('facebook')">
          <img src="./assets/icons/facebook-black.svg" alt="Facebook" />
        </div>
      </div>
    </div>
  </div>

  <!-- stats.component.html -->

  <div class="stats-container" *ngIf="visitsNumber !=undefined && cooptationNumber !=undefined">
    <div class="stats-header">{{'my statistics' | translate }}</div>
    <div class="stats-row">
      <div class="stat">
        <p class="stat-number">{{visitsNumber}}</p>
        <p class="stat-label">{{'people whom' | translate}}<br>{{'clicked my link' | translate}}</p> 
      </div>
      <div class="stat">
        <p class="stat-number">{{cooptationNumber}}</p>
        <p class="stat-label">{{'coopted candidats'| translate }}</p>
      </div>
    </div>
  </div>



  <div class="help-container">
    <h5 class="help-title"><span>{{'need help' | translate}}</span></h5>
    <p class="help-text">
      👉Consulter
      <a class="text-custom" href="https://storage.googleapis.com/basile-public/Com%20Basile.pdf" target="_blank">les
        règles de cooptation changer le lien</a>
    </p>
  </div>
</div>