 import { Component, Input, OnInit } from '@angular/core';
import { Job } from '../../data/job';
import { TranslationsService } from '../../services/translations.service';
import { environment } from '../../../environments/environment.full';

@Component({
  selector: 'app-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss']
})
export class JobOverviewComponent implements OnInit {

  public environment = environment;

  @Input()
  public job!: Job;

  @Input()
  public showLogo = true;

  @Input()
  public showContractType = true;

  constructor(
    translations: TranslationsService
  ) {
  }

  ngOnInit(): void {
  }

}
