<mat-expansion-panel (opened)="brandsOpenState = true" (closed)="brandsOpenState = false" [expanded]="brandsOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'Browse More Offers' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <div *ngFor="let brand of brands" class="checkbox-item">
      <mat-checkbox color="primary" (change)="brandsChanged(brand[0], $event.checked)">
        {{'See more Group offers' | translate}}
      </mat-checkbox>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel (opened)="typesOfContractOpenState = true" (closed)="typesOfContractOpenState = false" [expanded]="typesOfContractOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'Type of contract' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <div *ngFor="let type of typesOfContract" class="checkbox-item">
      <mat-checkbox color="primary" (change)="typeOfContractChanged(type[0], $event.checked)" [(ngModel)]="type[2]">
        {{notEmpty(type[0]) | translate}} ({{type[1]}})
      </mat-checkbox>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel (opened)="sectorsOpenState = true" (closed)="sectorsOpenState = false" [expanded]="sectorsOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'Sector' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <div *ngFor="let type of sectors" class="checkbox-item">
      <mat-checkbox color="primary" (change)="sectorChanged(type[0], $event.checked)" [(ngModel)]="type[2]">
       <span class="break-text"> {{notEmpty(type[0])}} ({{type[1]}}) </span>
      </mat-checkbox>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel (opened)="countriesOpenState = true" (closed)="countriesOpenState = false" [expanded]="countriesOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'Country' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <div *ngFor="let type of countries" class="checkbox-item">
      <mat-checkbox color="primary" (change)="countryChanged(type[0], $event.checked)" [(ngModel)]="type[2]">
        {{notEmpty(type[0])}} ({{type[1]}})
      </mat-checkbox>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel (opened)="locationsOpenState = true" (closed)="locationsOpenState = false" [expanded]="locationsOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'City' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <div *ngFor="let type of locations" class="checkbox-item">
      <mat-checkbox color="primary" (change)="locationChanged(type[0], $event.checked)" [(ngModel)]="type[2]">
        {{notEmpty(type[0])}} ({{type[1]}})
      </mat-checkbox>
    </div>
  </div>
</mat-expansion-panel>
