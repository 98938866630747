<h1>{{ "my cooptations" | translate }}</h1>

<div style="padding-bottom: 16px;">
    <button mat-raised-button color="accent" (click)="coopt()" style="padding: 20px 40px;">
        <mat-icon>person_add</mat-icon>
        {{'coopt candidate' | translate | uppercase}}</button>

</div>

<div *ngFor="let cooptation of cooptationsList" style="padding:20px 0;">
    <app-cooptation-card [cooptation]="cooptation" (relanceEvent)="relance($event)"> </app-cooptation-card>
</div>