import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JobSearchService } from './job-search.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  private translations: Map<string, Map<string, string>> = new Map([
    ['en', new Map([
      ['Apply Now', 'APPLY NOW'],
      ['All rights reserved', 'All rights reserved'],
      ['available offer', 'available offer'],
      ['available offers', 'available offers'],
      ['Compensation and benefits', 'Compensation and benefits'],
      ['City', 'City'],
      ['Country', 'Country'],
      ['Experience', 'Experience'],
      ['FOLLOW OUR ADVENTURES', 'FOLLOW OUR ADVENTURES'],
      ['in the area', 'in the area'],
      ['Job title or keywords', 'Job title or keywords'],
      ['Legal', 'Legal'],
      ['legal-link', 'https://abylsen.com/en/legal/'],
      ['More Jobs', 'More Jobs'],
      ['Offers', 'Abylsen recruting'],
      ['coopt', 'Coopt'],
      ['my cooptations', 'My cooptations'],
      ['faq', 'FAQ'],


      ['Privacy policy', 'Privacy policy'],
      ['privacy-policy-link', 'https://abylsen.com/en/privacy-policy/'],
      ['Requirements', 'Requirements'],
      ['Sector', 'Sector'],
      ['Browse More Offers', 'Browse More Offers'],

      ['my statistics', 'My Statistics'],
      ['people whom', 'People Whom'],
      ['clicked my link', 'Clicked my link'],




      ['link copied', 'Link copied'],
      ['by link', 'by link'],
      ['no offer selected', 'No offer selected'],


      ['slightly Recommended', 'Slightly Recommended'],
      ['strongly Recommended', 'Strongly Recommended'],
      ['recommended', 'Recommended'],

      ['sent at', 'Sent at'],
      ['share', 'Share'],

      ['coopted candidats', 'Coopted candidates'],
      ['need help','Need help?'],

      ['cooptation rules', 'The cooptation rules change the link'],





      ['Stage', 'Stage'],
      ['VIE', 'VIE'],
      ['Alternance', 'Alternance'],

      ['coopt candidate', 'coopt candidate'],
      ['cooptForThisOffer', 'Coopt for this offer'],
      ['CDI', 'CDI'],
      ['Temporary contract', 'CDD'],
      ['See more Group offers', 'Explore others group’s offers'],
      ['First Name', 'First Name'],
      ['Prénom', 'First Name'],
      ['Last Name', 'Last Name'],
      ['Nom', 'Last Name'],
      ['Have CV', 'Do you have the CV of your candidate?'],
      ['J’ai le CV de mon candidat', 'I have my candidate\'s CV'],
      ['Yes', 'Yes'],
      ['Oui', 'Yes'],
      ['No', 'No'],
      ['Non', 'No'],
      ['DragLabel', 'Drag and drop your file here or click to upload'],
      ['Faites glisser et déposez votre fichier ici ou cliquez pour télécharger', 'Drag and drop your file here or click to upload'],
      ['Attach CV', 'Attach CV'],
      ['Attacher CV', 'Attach CV'],
      ['Offer', 'Offer'],
      ['Offre', 'Offer'],
      ['Select Offer', 'Select Offer'],
      ['Choisir Offre', 'Choose Offer'],
      ['Candidate Choice', 'Candidate\'s Choice'],
      ['Choix du Candidat', 'Candidate\'s Choice'],
      ['Agency', 'Agency'],
      ['Agence', 'Agency'],
      ['Reason for Recommendation', 'Reason for Recommendation'],
      ['Raison de Recommandation', 'Reason for Recommendation'],
      ['High Recommendation', 'High Recommendation'],
      ['Haute Recommandation', 'High Recommendation'],
      ['Medium Recommendation', 'Medium Recommendation'],
      ['Recommandation Moyenne', 'Medium Recommendation'],
      ['Low Recommendation', 'Low Recommendation'],
      ['Basse Recommandation', 'Low Recommendation'],
      ['Submit', 'Submit'],
      ['Soumettre', 'Submit'],
      ['Consent', 'Consent'],
      ['Consentement', 'Consent'],
      ['who recommand', 'Who do you want to recommend?'],
      ['Qui souhaitez-vous recommander ?', 'Who do you want to recommend?'],
      ['Selected File', 'Selected File'],
      ['Fichier Sélectionné', 'Selected File'],
      ['close', 'Close'],
      ['Fermer', 'Close'],
      ['upload', 'Upload'],
      ['Importer', 'Upload'],
      ['comment', 'The comment should be relevant, objective, and focus on the candidate’s skills and abilities. Any mention of sensitive data (origin, opinions, health, etc.) is strictly prohibited.'],
      ['Le commentaire doit être pertinent, objectif, se limiter aux compétences et aux aptitudes du candidat. Toute mention relative à des données sensibles (origine, opinions, santé…) est strictement interdite.', 'The comment must be relevant, objective, limited to the candidate\'s skills and abilities. Any mention of sensitive data (origin, opinions, health, etc.) is strictly prohibited.'],
      ['File Size', 'File Size'],
      ['Taille Ficher', 'File Size'],
      ['recommand Offer', 'Would you like to recommend your candidate for an offer?'],
      ['Souhaitez-vous recommander votre candidat pour une offre ?', 'Would you like to recommend your candidate for an offer?'],
      ['Choose offer', 'Choose an offer'],
      ['Choisir une offre', 'Choose an offer'],
      ['link to agency', 'Please attach your candidate to an Abylsen agency.'],
      ['Merci de rattacher votre candidat à une agence Abylsen.', 'Please link your candidate to an Abylsen agency.'],
      ['Choose agency', 'Choose an agency'],
      ['Choisir une agence', 'Choose an agency'],
      ['why recommand person', 'Why are you recommending this person?'],
      ['Pourquoi recommandez-vous cette personne ?', 'Why are you recommending this person?'],
      ['user choose', 'Let my candidate choose'],
      ['Laisser mon candidat choisir', 'Let my candidate choose'],
      ['know the person', 'I have worked with this person and strongly recommend them.'],
      ['J’ai déjà travaillé avec cette personne et je la recommande fortement.', 'I have already worked with this person and strongly recommend them.'],
      ['dont know the person', 'I recommend this person, but I have not worked with them.'],
      ['Je recommande cette personne, mais je n’ai pas travaillé avec elle.', 'I recommend this person, but I have not worked with them.'],
      ['he asks for recommandation', 'I do not know this person: I am recommending them at their request or at the request of someone else.'],
      ['Je ne connais pas cette personne: je la recommande à sa demande ou à la demande d une autre personne', 'I do not know this person: I am recommending them at their request or someone else’s request.'],
      ['sponatnious candidate', 'Your candidate can either select an offer or apply spontaneously.'],
      ['Votre candidat pourra sélectionner une offre ou postuler en candidature spontanée.', 'Your candidate can select an offer or apply spontaneously.'],
      ['list', 'List'],
      ['Liste', 'List'],
      ['placeholder know the person', 'I know this person as... (colleague, partner, client, etc.), and I recommend them for... (experiences, skills, qualities).'],
      ['protection data', 'Under the applicable data protection regulations (GDPR), personal data concerning you is collected and processed by Abylsen Group as the data controller. This information is necessary for the processing and follow-up of your referral request. Fields marked with an asterisk (*) are mandatory. You have the right to access, rectify, erase, limit, and object to the processing of your data. For more information regarding the processing of your personal data, you can consult the information notices on the protection of personal data below.'],
      ['I accept', 'I accept the'],
      ['J’accepte les', 'I accept the'],
      ['legal mentions', 'legal notices'],
      ['mentions légales', 'legal notices'],
      ['and the', 'and the'],
      ['et la', 'and the'],
      ['policy', 'privacy policy'],
      ['politique de confidentialité', 'privacy policy'],
      ['recommand', 'Recommend'],
      ['Recommander', 'Recommend'],
      ['no risk title', 'No risk to your career!'],
      ['Sans risque pour ta carrière !', 'No risk to your career!'],
      ['no risk message', 'Your career and well-being are our priority. Regardless of the outcome of the referral, your interests will be protected, and you can always refer others!'],
      ['Ta carrière et ton bien-être est notre priorité, peu importe l’issue de la cooptation tes intérêts seront protégés et tu pourras toujours coopter !', 'Your career and well-being are our priority. Regardless of the outcome of the referral, your interests will be protected, and you can always refer others!'],


      ['Permanent contract', 'Permanent contract'],

      ['Share:', 'Share:'],
      ['Show more offers', 'Show more offers'],
      ['Spontaneous Application', 'Spontaneous Application'],
      ['Submitting application', 'We register your application'],
      ['The offer', 'The offer'],
      ['Type of contract', 'Type of contract'],
      ['Unspecified', 'Unspecified'],
      ['View All Offers', 'VIEW ALL OFFERS'],
      ['Your application has been received', 'Your application has been received. We will contact you very soon.'],
      ['JOB NOT FOUND', 'Sorry but the job is not available anymore'],
      ['LinkedIn-button-text', 'Share my profile'],
      ['application-form-first-title', 'Application'],
      ['application-form-linked-in-label', 'With my LinkedIn profile'],
      ['application-form-cv-label', 'My CV'],
      ['application-form-apply-button', 'SEND MY APPLICATION'],
      ['application-form-accepted-formats', 'Formats: PDF, DOC'],
      ['application-form-cv-missing-error', 'The CV is mandatory, please attach it using the button above'],
      ['spontaneous application form.agency.title', 'You want to be contacted by'],
      ['_connexys_language', 'Anglais'],
      ['READY_TO_EXPLORE', 'Ready to explore ?'],
      ['SSO_connexion', 'Connection SSO'],
      ['Log out', 'Log out']


    ])],
    ['fr', new Map([
      ['Apply Now', 'POSTULER À CETTE OFFRE'],
      ['All rights reserved', 'Tous droits réservés'],
      ['available offer', 'offre disponible'],
      ['available offers', 'offres disponibles'],
      ['Compensation and benefits', 'Rémunération et avantages'],
      ['City', 'Ville'],
      ['Country', 'Pays'],
      ['Experience', 'Expérience'],
      ['FOLLOW OUR ADVENTURES', 'SUIVEZ NOS AVENTURES'],
      ['in the area', 'dans le secteur'],
      ['Job title or keywords', 'Titre du poste ou mots-clefs'],
      ['Legal', 'Mentions légales'],
      ['legal-link', 'https://abylsen.com/mentions-legales/'],
      ['More Jobs', 'Plus d\'offres'],
      ['Offers', 'Abylsen recrute'],
      ['coopt', 'Coopter'],
      ['my cooptations', 'Mes cooptations'],
      ['faq', 'FAQ'],
      ['cooptation sent success', 'La cooptation a été envoyée avec succès.'],
      ['error sent cooptation', 'Une erreur est survenue côté serveur, merci de réessayer plus tard.'],



      ['Privacy policy', 'Politique de Confidentialité'],
      ['privacy-policy-link', 'https://abylsen.com/politique-de-confidentialite/'],
      ['Requirements', 'Profil recherché'],
      ['Permanent contract', 'CDI'],
      ['Temporary contract', 'CDD'],

      ['Sector', 'Secteur'],
      ['See more Group offers', 'Découvrir les autres offres du Groupe'],
      ['Browse More Offers', 'Plus d’offres'],
      ['share', 'Partager'],
      ['link copied', 'Texte copié'],
      ['my statistics', 'Mes Statistiques'],

      ['people whom', 'personnes ont'],
      ['clicked my link', 'cliqué sur mon lien'],

      ['slightly Recommended', 'Un peu recommandé'],
      ['strongly Recommended', 'Fortement recommandé'],
      ['recommended', 'Recommandé'],
      ['sent at', 'Envoyé le'],
      ['coopted candidats', 'candidats cooptées'],
      ['need help','Besoin d aide sur la cooptation ?'],
      ['cooptation rules', 'les règles de cooptation changer le lien'],


      ['no offer selected', 'Aucune offre sélectionnée'],

      ['by link', 'Via Lien'],
      ['Stage', 'Stage'],

      ['VIE', 'VIE'],
      ['Alternance', 'Alternance'],

      ['reminder sent success', 'La relance a été envoyée avec succès.'],
      ['coopt candidate', 'Coopter un Candidat'],


      ['cooptForThisOffer', 'Coopter pour cette offre'],
      ['CDI', 'CDI'],
      ['CDD', 'CDD'],

      ['First Name', 'Prénom'],
      ['Last Name', 'Nom'],
      ['Have CV', 'J’ai le CV de mon candidat'],
      ['Yes', 'Oui'],
      ['No', 'Non'],
      ['DragLabel', 'Faites glisser et déposez votre fichier ici ou cliquez pour télécharger'],
      ['Attach CV', 'Attacher CV'],
      ['Offer', 'Offre'],
      ['Select Offer', 'Choisir Offre'],
      ['Candidate Choice', 'Choix du Candidat'],
      ['Agency', 'Agence'],
      ['Reason for Recommendation', 'Raison de Recommandation'],
      ['High Recommendation', 'Haute Recommandation'],
      ['Medium Recommendation', 'Recommandation Moyenne'],
      ['Low Recommendation', 'Basse Recommandation'],
      ['Submit', 'Soumettre'],
      ['Consent', 'Consentement'],
      ['who recommand', 'Qui souhaitez-vous recommander ?'],
      ['Selected File', 'Selected File'],
      ['close', 'Fermer'],
      ['upload', 'Importer'],
      ['comment', 'Le commentaire doit être pertinent, objectif, se limiter aux compétences et aux aptitudes du candidat. Toute mention relative à des données sensibles (origine, opinions, santé…) est strictement interdite.'],
      ['File Size', 'Taille Ficher'],
      ['recommand Offer', 'Souhaitez-vous recommander votre candidat pour une offre ?'],
      ['Choose offer', 'Choisir une offre'],
      ['link to agency', 'Merci de rattacher votre candidat à une agence Abylsen.'],
      ['Choose agency', 'Choisir une agence'],
      ['why recommand person', 'Pourquoi recommandez-vous cette personne ?'],
      ['user choose', 'Laisser mon candidat choisir'],
      ['know the person', '  J’ai déjà travaillé avec cette personne et je la recommande fortement.'],
      ['dont know the person', 'Je recommande cette personne, mais je n’ai pas travaillé avec elle.'],
      ['he asks for recommandation', 'Je ne connais pas cette personne: je la recommande à sa demande ou à la demande d une autre personne'],
      ['sponatnious candidate', 'Votre candidat pourra sélectionner une offre ou postuler en candidature spontanée.'],
      ['list', 'Liste'],
      ['placeholder know the person', 'Je connais cette personne comme... (ancien collègue, partenaire, client...), et je la recommande pour...(expériences, compétences, qualités...). '],
      ['protection data', 'En vertu de la réglementation applicable en matière de protection des données personnelles (RGPD), des données personnelles vous concernant sont collectées et traitées par Abylsen Group en tant que responsable de traitement. Ces informations sont nécessaires pour le traitement et le suivi de votre demande de cooptation. Les champs mentionnés par un astérisque (*) sont obligatoires. Vous disposez d’un droit d’accès, de rectification, d’effacement, de limitation et d’opposition au traitement de vos données. Pour plus d’informations concernant le traitement de vos données à caractère personnel, vous pouvez consulter les mentions  d’information sur la protection des données personnelles ci-dessous.'],
      ['I accept', 'J’accepte les'],
      ['legal mentions', 'mentions légales'],
      ['and the', 'et la'],
      ['policy', 'politique de confidentialité'],
      ['recommand', 'Recommander'],
      ['no risk title', 'Sans risque pour ta carrière !'],
      ['no risk message', 'Ta carrière et ton bien-être est notre priorité, peu importe l’issue de la cooptation tes intérêts seront protégés et tu pourras toujours coopter !'],


      ['Share:', 'Partager :'],
      ['Show more offers', 'Afficher plus d\'offres'],
      ['Spontaneous Application', 'Candidature Spontanée'],
      ['Submitting application', 'Nous enregistrons votre candidature'],
      ['The offer', 'L\'offre'],
      ['Type of contract', 'Type de contrat'],
      ['Unspecified', 'Non spécifié'],
      ['View All Offers', 'AFFICHER TOUTES LES OFFRES'],
      ['Your application has been received', 'Votre candidature a bien été reçue. Nous vous contacterons très prochainement.'],
      ['JOB NOT FOUND', 'Malheureusement cette offre n\'est plus disponible'],
      ['LinkedIn-button-text', 'Partager mon profil'],
      ['application-form-first-title', 'Candidature'],
      ['application-form-linked-in-label', 'Avec mon profil LinkedIn'],
      ['application-form-cv-label', 'Mon CV'],
      ['application-form-apply-button', 'ENVOYER MA CANDIDATURE'],
      ['application-form-accepted-formats', 'Formats: PDF, DOC'],
      ['application-form-cv-missing-error', 'Le CV est obligatoire, merci de l\'attacher en utilisant le bouton ci-dessus'],
      ['spontaneous application form.agency.title', 'Vous souhaitez être contacté par'],
      ['_connexys_language', 'Français'],
      ['READY_TO_EXPLORE', 'Prêt pour l\'exploration ?'],
      ['SSO_connexion', 'Connexion SSO'],
      ['Log out', 'Se déconnecter']
    ])]
  ]);

  private lang = 'fr';

  constructor(
    private searchService: JobSearchService,
    private router: Router
  ) {
    /*
    let s = localStorage.getItem('lang');
    if (s && this.translations.has(s)) {
      this.lang = s;
    } else if (window.navigator && window.navigator.language) {
      s = window.navigator.language.toLowerCase();
      if (s.length > 2) {
        s = s.substring(0, 2);
      }
      if (this.translations.has(s)) {
        this.lang = s;
      }
    }
    */
    this.lang = 'fr';
    localStorage.setItem('lang', this.lang);
    document.documentElement.setAttribute("lang", this.lang);
    this.searchService.lg = this.lang;
    this.searchService.resetJobs();
  }

  public translate(key: string): string {
    const lang = this.translations.get(this.lang);
    if (lang) {
      const translation = lang.get(key);
      if (translation) {
        return translation;
      }
    }
    return '!!! ' + key + ' !!!';
  }

  public getLanguage(): string {
    return this.lang;
  }

  public setLanguage(lang: string): void {
    if (this.lang == lang) {
      return;
    }
    this.lang = lang;
    localStorage.setItem('lang', lang);
    document.documentElement.setAttribute("lang", this.lang);
    this.searchService.lg = this.lang;
    this.searchService.resetJobs();
    this.router.navigateByUrl('/');
  }
}
