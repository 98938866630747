// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  assetsUrl: '/assets',
  applyFormServer: 'https://abylsengroup--full.sandbox.my.salesforce-sites.com/',
  jobApplicationForm: 'resource/cxsrec__cxsForm',
  spontaneousApplicationForm: 'resource/cxsrec__cxsForm',
  spontaneousApplicationFormId: 'a005r000002wBHd',
  linkedInClientId: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
