import { Component, Input } from '@angular/core';
import { BrandingService } from '../../services/branding.service';
import { environment } from '../../../environments/environment.full';
 
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input()
  public size = 'normal';

  public imgUrl = environment.assetsUrl + '/logo-small-black.png';

  constructor(
    private branding: BrandingService
  ) {
    this.imgUrl = environment.assetsUrl + '/brands/' + branding.brand.name + '/waiting.png';
  }


}
