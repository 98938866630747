import { MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JobOverviewComponent } from './components/job-overview/job-overview.component';
import { JobListComponent } from './components/job-list/job-list.component';
import { JobDetailComponent } from './components/job-detail/job-detail.component';
import { JobSearchCriteriaComponent } from './components/job-search-criteria/job-search-criteria.component';
import { JobFiltersComponent } from './components/job-filters/job-filters.component';
import { JobSearchPageComponent } from './pages/job-search-page/job-search-page.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslatePipe } from './services/translate.pipe';
import { JobPageComponent } from './pages/job-page/job-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { LinkedinButtonComponent } from './linkedin/linkedin-button/linkedin-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MyCooptationComponent } from './components/my-cooptation/my-cooptation.component';
import { FAQComponent } from './components/faq/faq.component';
import { CooptationFormComponent } from './components/cooptation-form/cooptation-form.component';
import { CooptationCardComponent } from './components/cooptation-card/cooptation-card.component';
import {MatDividerModule} from '@angular/material/divider';
import { ShareOfferComponent } from './components/share-offer/share-offer.component';
import { FaqContentComponent } from './components/faq-content/faq-content.component';
import { CooptationsListComponent } from './components/cooptations-list/cooptations-list.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

// Define MSALInstanceFactory
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '136b68d6-aff4-438c-b10b-f0b930de8b36', 
      authority: 'https://login.microsoftonline.com/3fe99b51-e7b8-4586-ac15-d51c0df4d4a4',
      redirectUri: 'https://testcoopt.abylsen.com',
      postLogoutRedirectUri: 'https://testcoopt.abylsen.com/',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}



@NgModule({
  declarations: [
    AppComponent,
    JobOverviewComponent,
    JobListComponent,
    JobDetailComponent,
    JobSearchCriteriaComponent,
    JobFiltersComponent,
    JobSearchPageComponent,
    LoadingComponent,
    HeaderComponent,
    TranslatePipe,
    JobPageComponent,
    FooterComponent,
    LinkedinButtonComponent,
    MyCooptationComponent,
    FAQComponent,
    CooptationFormComponent,
    LoginPageComponent,
    CooptationsListComponent,
    CooptationCardComponent,
    ShareOfferComponent,
    FaqContentComponent
  ],
  imports: [
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinner,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
