import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobSearchService } from '../../services/job-search.service';
import { TranslationsService } from '../../services/translations.service';
import { BrandingService } from '../../services/branding.service';
 
@Component({
  selector: 'app-job-filters',
  templateUrl: './job-filters.component.html',
  styleUrls: ['./job-filters.component.scss']
})
export class JobFiltersComponent implements OnInit, OnDestroy {
  public brandsOpenState = true;
  public brands: [string, number, boolean][] = [];

  public typesOfContractOpenState = true;
  public typesOfContract: [string, number, boolean][] = [];
  public typesOfContractSubscription?: Subscription = undefined;

  public sectorsOpenState = true;
  public sectors: [string, number, boolean][] = [];
  public sectorsSubscription?: Subscription = undefined;

  public countriesOpenState = true;
  public countries: [string, number, boolean][] = [];
  public countriesSubscription?: Subscription = undefined;

  public locationsOpenState = true;
  public locations: [string, number, boolean][] = [];
  public locationsSubscription?: Subscription = undefined;

  private displayValueComparator = (v1: [string, number, boolean], v2: [string, number, boolean]) => {
    const s1 = this.notEmpty(v1[0]);
    const s2 = this.notEmpty(v2[0]);
    return s1.localeCompare(s2, this.translations.getLanguage());
  }

  constructor(
    public service: JobSearchService,
    private translations: TranslationsService,
    public branding: BrandingService,

  ) {
  }

  private setTypesOfContract(types: IterableIterator<[string, number]>): void {
    this.typesOfContract = [];
    for (let type of types) {
      this.typesOfContract.push([type[0], type[1], this.service.criteria.typesOfContract.indexOf(type[0]) >= 0]);
    }
    this.typesOfContract.sort(this.displayValueComparator);
  }

  private setSectors(types: IterableIterator<[string, number]>): void {
    this.sectors = [];
    for (let type of types) {
      this.sectors.push([type[0], type[1], this.service.criteria.sectors.indexOf(type[0]) >= 0]);
    }
    this.sectors.sort(this.displayValueComparator);
  }

  private setCountries(types: IterableIterator<[string, number]>): void {
    this.countries = [];
    for (let type of types) {
      this.countries.push([type[0], type[1], this.service.criteria.countries.indexOf(type[0]) >= 0]);
    }
    this.countries.sort(this.displayValueComparator);
  }

  private setLocations(types: IterableIterator<[string, number]>): void {
    this.locations = [];
    for (let type of types) {
      this.locations.push([type[0], type[1], this.service.criteria.locations.indexOf(type[0]) >= 0]);
    }
    this.locations.sort(this.displayValueComparator);
  }

  private setBrands() {
    this.brands = [[this.branding.brand.displayName, 10, true]];
    // this.branding.brands.forEach(p=>this.brands.push([p.displayName,10,true]));
    // this.brands.push([this.branding.brand.displayName, 10, true]);
    // this.brands.push(['See All', 10, true]);
  }


  ngOnInit(): void {
    this.setBrands();

    this.typesOfContractSubscription = this.service.typesOfContract$.subscribe(value => this.setTypesOfContract(value.entries()));
    this.sectorsSubscription = this.service.sectors$.subscribe(value => this.setSectors(value.entries()));
    this.countriesSubscription = this.service.countries$.subscribe(value => this.setCountries(value.entries()));
    this.locationsSubscription = this.service.locations$.subscribe(value => this.setLocations(value.entries()));
  }

  ngOnDestroy(): void {
    this.typesOfContractSubscription?.unsubscribe();
    this.sectorsSubscription?.unsubscribe();
    this.countriesSubscription?.unsubscribe();
    this.locationsSubscription?.unsubscribe();
  }

  public brandsChanged(name: string, checked: boolean): void {
    this.branding.setMultiBrand(name, checked);
    this.service.resetJobs();
  }


  public typeOfContractChanged(type: string, checked: boolean): void {
    this.criteriaChanged(this.service.criteria.typesOfContract, type, checked);
  }

  public sectorChanged(type: string, checked: boolean): void {
    this.criteriaChanged(this.service.criteria.sectors, type, checked);
  }

  public countryChanged(type: string, checked: boolean): void {
    this.criteriaChanged(this.service.criteria.countries, type, checked);
  }

  public locationChanged(type: string, checked: boolean): void {
    this.criteriaChanged(this.service.criteria.locations, type, checked);
  }

  public notEmpty(value?: string): string {
    if (value && value.trim().length > 0) {
      return value;
    }
    return this.translations.translate('Unspecified');
  }

  private criteriaChanged(criteria: string[], type: string, checked: boolean): void {
    const i = criteria.indexOf(type);
    if (checked) {
      if (i >= 0) {
        return;
      }
      criteria.push(type);
    } else {
      if (i < 0) {
        return;
      }
      criteria.splice(i, 1);
    }
    this.service.resetJobs();
  }

}
