<div class="top-bg">
</div>
<div class="container">
  <div class="header">
    <span>{{ "my cooptations" | translate | uppercase }}</span>
  </div>

  <!-- Flex container -->
  <div class="content-row">
    <app-cooptations-list [cooptationsList]="cooptationsList" (refreshCooptantList)="getCooptations(ssoId)" ></app-cooptations-list>
    <app-share-offer [cooptationNumber]="cooptationNumber" [visitsNumber]="visitsNumber" ></app-share-offer>
  </div>

</div>
