import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Job } from '../../data/job';
import { DialogService } from '../../services/dialog.service';
@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent {

  @Input()
  public jobs!: Job[];

  @Input()
  public showLogo = true;

  @Input()
  public showContractType = true;

  @Input()
  public openedViaDialog!: boolean;
  constructor(
    private router: Router,
    public dialogService: DialogService,
  ) { }

  public viewJob(job: Job): void {
    if (!this.openedViaDialog)
      this.router.navigate(['/job/' + job.uid], { queryParamsHandling: 'preserve' });
    else {
      this.dialogService.closeDialog(job)
    }
  }

}
