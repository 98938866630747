import { Cooptation } from './../../data/cooptation';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationsService } from '../../services/translations.service';
import { SocialMediaService } from '../../services/social-media.service';
import { CooptationService } from '../../services/cooptation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedMethods } from '../../shared/shared-methods';

@Component({
  selector: 'app-share-offer',
  templateUrl: './share-offer.component.html',
  styleUrl: './share-offer.component.scss',
})


export class ShareOfferComponent implements OnInit {


  @Input() visitsNumber!: number;
  @Input() cooptationNumber!: number;



  constructor(
    private router: Router,
    private socialMediaService: SocialMediaService,
    public cooptationService: CooptationService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private sharedMethod: SharedMethods


  ) {}

 


  inputText: string = '';
  copySuccess: boolean = false;
  currentUrl!: string;
  token: string | undefined;


  
  ngOnInit() {
    this.currentUrl = this.router.url;

    if (this.currentUrl.includes('/job/')) {
      this.token = this.currentUrl.split('/').pop();
      this.socialMediaService
        .getShareLink(this.token)
        .subscribe((res: any) => {
          this.inputText = res.shortLink;
        });
    } else {
      this.socialMediaService.getShareLink().subscribe((res: any) => {
        this.inputText = res.shortLink;
      });
    }
  }

  copyToClipboard() {
    const inputElement = document.createElement('textarea');
    inputElement.value = this.inputText;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
    setTimeout(() => {
      this.dialog.closeAll();
      this.sharedMethod.openSnackBar('Texte copié !', "OK");
    }, 0);    

  }

  shareOffer(socialMedia: string) {
    this.socialMediaService.share(socialMedia, 'window.location.href', 'job');
  }
}
