import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, switchMap } from 'rxjs';
import { Job } from '../../data/job';
import { JobSearchService } from '../../services/job-search.service';
import { BrandingService } from '../../services/branding.service';
  
@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.scss']
})
export class JobPageComponent implements OnInit, OnDestroy {

  public job?: Job | null = undefined;
  public isApply = false;
  private paramsSubscription?: Subscription;
  private urlSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private jobService: JobSearchService,
    private router: Router,
    private branding: BrandingService
  ) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.paramMap.pipe(
      switchMap(params => {
        const uid = params.get('jobId');
        if (uid) {
          return this.jobService.getJobByUid(uid);
        }
        return of(null);
      })
    ).subscribe(job => {
      this.job = job;
      document.title = job?.title + ' - Jobs - ' + this.branding.brand.displayName;
    });
    this.urlSubscription = this.route.url.subscribe(url => {
      this.isApply = url[url.length - 1].path == 'apply';
    });
  }

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  public viewAllOffers() {
    this.router.navigateByUrl('/');
  }
}
