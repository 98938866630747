<div class="page-footer" [ngSwitch]="branding.brand.name">
  <ng-container *ngSwitchCase="'abylsen'">
    <div class="logo abylsen">
      <img src="{{assetsUrl}}/logo-abylsen.png">
    </div>
    <div class="footer-row">
      <div class="social">
        <div>
          {{'FOLLOW OUR ADVENTURES' | translate}}
        </div>
        <div class="social-icons">
          <a href="https://www.instagram.com/abylsengroup/" target="_blank">
            <img src="{{assetsUrl}}/instagram-white-24.png">
          </a>
          <a href="https://www.linkedin.com/company/abylsen/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-white-24.png">
          </a>
          <a href="https://www.youtube.com/channel/UCDHCxUSxCch2xpcp8BVmhmg" target="_blank">
            <img src="{{assetsUrl}}/youtube-white-24.png">
          </a>
        </div>
      </div>

      <div class="hohr">
        <a href="https://www.houseofhr.com/" target="_blank">
          <img src="{{assetsUrl}}/logo_house_of_hr.png">
        </a>
      </div>
    </div>

    <div class="happy-at-work">
      <img src="{{assetsUrl}}/happy-at-work.europe.jpg" width="200">
    </div>

    <div class="legal">
      <div class="copyright">
        Abylsen – {{thisYear}} – {{'All rights reserved' | translate}}
      </div>
      <div class="legal-and-privacy">
        <a [href]="legalLink" target="_blank">{{'Legal' | translate}}</a>
        &nbsp;
        <a [href]="privacyPolicyLink" target="_blank">{{'Privacy policy' | translate}}</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'mykelson-consulting'">
    <div class="footer-row">
      <div class="social">
        <div>
          {{'FOLLOW OUR ADVENTURES' | translate}}
        </div>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/mykelson-consulting/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-white-24.png">
          </a>
          <a href="https://twitter.com/MykelsonC" target="_blank">
            <img src="{{assetsUrl}}/twitter-white-24.png">
          </a>
        </div>
      </div>
      <div class="hohr">
        <a href="https://www.houseofhr.com/" target="_blank">
          <img src="{{assetsUrl}}/logo_house_of_hr.png">
        </a>
      </div>
    </div>
    <div class="legal">
      <div class="copyright">
        Mykelson Consulting – {{thisYear}} – {{'All rights reserved' | translate}}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'belgatech'">
    <div class="footer-row">
      <div class="social">
        <div>
          {{'FOLLOW OUR ADVENTURES' | translate}}
        </div>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/belgatech-engineering-services/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-white-24.png">
          </a>
          <a href="https://www.facebook.com/BELGATECHEngineeringServices/" target="_blank">
            <img src="{{assetsUrl}}/facebook-white-24.png">
          </a>
          <a href="https://www.youtube.com/channel/UCHh401EH9LSD0K4rw-_2gnw" target="_blank">
            <img src="{{assetsUrl}}/youtube-white-24.png">
          </a>
        </div>
      </div>
      <div class="hohr">
        <a href="https://www.houseofhr.com/" target="_blank">
          <img src="{{assetsUrl}}/logo_house_of_hr.png">
        </a>
      </div>
    </div>
    <div class="legal">
      <div class="copyright">
        BELGATECH – {{thisYear}} – {{'All rights reserved' | translate}}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'pi'">
    <div class="logo pi">
      <img src="{{assetsUrl}}/brands/pi/logo_white_transparent_1931x784.png"/>
    </div>
    <div class="footer-row">
      <div class="social">
        <div>
          {{'FOLLOW OUR ADVENTURES' | translate}}
        </div>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/pilifesciences/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-white-24.png">
          </a>
        </div>
      </div>
      <div class="hohr">
        <a href="https://www.houseofhr.com/" target="_blank">
          <img src="{{assetsUrl}}/logo_house_of_hr.png">
        </a>
      </div>
    </div>
    <div class="legal">
      <div class="copyright">
        pi Life Sciences – {{thisYear}} – {{'All rights reserved' | translate}}
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'pharmacos'">
    <div class="footer-row">
      <div class="logo pharmacos">
        <img src="{{assetsUrl}}/brands/pharmacos/pharmacos_logo_bleu.svg"/>
      </div>

      <div class="social">
        <div>
          {{'FOLLOW OUR ADVENTURES' | translate}}
        </div>
        <div class="social-icons">
          <a href="https://www.instagram.com/pharmacos__/" target="_blank">
            <img src="{{assetsUrl}}/instagram-dark-grey-24.png">
          </a>
          <a href="https://www.linkedin.com/company/pharmacos/" target="_blank">
            <img src="{{assetsUrl}}/linkedin-dark-grey-24.png">
          </a>
          <a href="https://twitter.com/Pharmacos_Group" target="_blank">
            <img src="{{assetsUrl}}/twitter-dark-grey-24.png">
          </a>
        </div>
      </div>

      <div class="hohr">
        <a href="https://www.houseofhr.com/" target="_blank">
          <img src="{{assetsUrl}}/logo_house_of_hr_black.png">
        </a>
      </div>
    </div>

    <div class="legal">
      <div class="copyright">
        Pharmacos – {{thisYear}} – {{'All rights reserved' | translate}}
      </div>
    </div>
  </ng-container>
</div>
