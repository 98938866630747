<div class="top-bg">
</div>
<div class="container">
  <div class="header">
    <span> FAQ </span>
  </div>

  <!-- Flex container -->
  <div class="content-row">
    <app-faq-content>
    </app-faq-content>
    <app-share-offer>
    </app-share-offer>
  </div>

</div>